import React from 'react'
import Layout from '../components/layout'
import BriefingGuide from '../components/briefing-guide/briefing-guide'

const Patient = props => (
  <Layout location={props.location}>
    <BriefingGuide type="patient" />
  </Layout>
)
export default Patient
